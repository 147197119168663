import { useEffect } from 'react';
import { useAppSelector } from 'redux/appStore';
import {
  getHasUserDeniedPermission,
  getIsGeoFilterTypeGps,
} from 'redux/geolocation/geolocation.slice';

const useGeoFilterTypeGpsObserver = ({
  onInvalidFilterSelection,
}: {
  onInvalidFilterSelection: Function;
}): null => {
  const isFilterTypeGps = useAppSelector(getIsGeoFilterTypeGps);
  const hasUserDeniedPermission = useAppSelector(getHasUserDeniedPermission);

  // if user blocks browser permissions at the moment the selected geoFilter is a GPS filter.
  useEffect(() => {
    if (hasUserDeniedPermission && isFilterTypeGps) {
      onInvalidFilterSelection();
    }
  }, [hasUserDeniedPermission, isFilterTypeGps, onInvalidFilterSelection]);

  return null;
};

export default useGeoFilterTypeGpsObserver;
