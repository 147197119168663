import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { TFunction } from 'i18next';
import { SortingSelection } from 'lib/SortingSelector/InitiallySelectedSorting';
import { Item } from 'types/ui';

export enum SORTING_OPTIONS {
  createdAt = 'createdAt',
  price = 'price',
  discount = 'discount',
  popular = 'popular',
  relevance = '',
}

export enum SORTING_DIRECTIONS {
  desc = 'desc',
  asc = 'asc',
}

export function getFallBackSortingOptionSearch(): SortingSelection {
  return {
    option: SORTING_OPTIONS.relevance,
    direction: SORTING_DIRECTIONS.desc,
  };
}

export function getFallBackSortingOptionCategory(): SortingSelection {
  return {
    option: SORTING_OPTIONS.popular,
    direction: SORTING_DIRECTIONS.desc,
  };
}

export function getSortingOrderItems(isSearch = false): Item<SortingSelection>[] {
  let orderOptions = [
    {
      label: 'app.ui.section.select_sorting.option_latest_first',
      value: {
        option: SORTING_OPTIONS.createdAt,
        direction: SORTING_DIRECTIONS.desc,
      },
    },
    {
      label: 'app.ui.section.select_sorting.option_popularity',
      value: {
        option: SORTING_OPTIONS.popular,
        direction: SORTING_DIRECTIONS.desc,
      },
    },
    {
      label: 'app.ui.section.select_sorting.option_price_desc',
      icon: BsArrowUp,
      value: {
        option: SORTING_OPTIONS.price,
        direction: SORTING_DIRECTIONS.asc,
      },
    },
    {
      label: 'app.ui.section.select_sorting.option_price_asc',
      icon: BsArrowDown,
      value: {
        option: SORTING_OPTIONS.price,
        direction: SORTING_DIRECTIONS.desc,
      },
    },
    {
      label: 'app.ui.section.select_sorting.option_discount_asc',
      icon: BsArrowUp,
      value: {
        option: SORTING_OPTIONS.discount,
        direction: SORTING_DIRECTIONS.desc,
      },
    },
    {
      label: 'app.ui.section.select_sorting.option_discount_desc',
      icon: BsArrowDown,
      value: {
        option: SORTING_OPTIONS.discount,
        direction: SORTING_DIRECTIONS.asc,
      },
    },
  ];

  if (isSearch) {
    orderOptions = [
      {
        label: 'app.ui.section.select_sorting.option_relevance',
        value: {
          option: SORTING_OPTIONS.relevance,
          direction: SORTING_DIRECTIONS.desc,
        },
      },
      ...orderOptions,
    ];
  }

  return orderOptions;
}
