import { AppThunk } from 'redux/appStore';
import productApi from 'api/product';
import { setGeoCities, setMarkers } from 'redux/geolocation/geoProducts.slice';
import { Taxon } from 'types/taxons';
import { captureError } from 'lib/error';
import { mapToProductsMarkers } from 'lib/address/mapToProductMakers';
import { ParsedUrlQuery } from 'querystring';
import { RequestWithCookies } from 'types/request';
import { resolveFallbackGeoFilter } from 'lib/geolocation/resolveFallbackGeoFilter';
import { getLocationByQueryParams } from 'lib/router/parsedUrlQuery/locationMapper';
import { setGeoFilter } from 'redux/geolocation/geolocation.slice';

export const fetchCities = (): AppThunk => async (dispatch) => {
  const result = await productApi.fetchProductCities();
  if (result?.length) dispatch(setGeoCities(result));
};

export const processGeoFilter =
  (query: ParsedUrlQuery | undefined, req: RequestWithCookies): AppThunk =>
  async (dispatch) => {
    const cities = await productApi.fetchProductCities();
    const fallbackFilter = resolveFallbackGeoFilter(req);
    const geoFilter = await getLocationByQueryParams(fallbackFilter, query, cities);

    dispatch(setGeoCities(cities));
    dispatch(setGeoFilter(geoFilter));
  };

export const fetchProductsForMap =
  (taxon: Taxon | null): AppThunk =>
  async (dispatch) => {
    try {
      const products = await productApi.fetchAllProductsByLocation({
        ...(taxon && taxon.id && { taxonId: taxon?.id }),
      });

      dispatch(setMarkers(mapToProductsMarkers(products)));
    } catch (e: unknown) {
      captureError(e as Error);
    }
  };
