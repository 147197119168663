import { PostalAddress } from 'src/types/types';
import { base64Decode, base64Encode } from '../strings';

export function encodePostalAddress(addressData: PostalAddress) {
  return base64Encode(JSON.stringify(addressData));
}

export function decodePostalAddress(hash: string): PostalAddress | null {
  try {
    const decodedAddress = JSON.parse(base64Decode(hash));
    if (
      decodedAddress.postcode &&
      decodedAddress.houseNumber &&
      decodedAddress.latitude &&
      decodedAddress.longitude &&
      decodedAddress.city
    ) {
      return decodedAddress;
    }
  } catch (error) {
    return null;
  }

  return null;
}
