import {
  FallBackLocationChain,
  LocationChainProviderInput,
} from 'lib/geolocation/locationChain/LocationProviderHandler';
import { getDefaultLocationFilter } from 'lib/geolocation/mapToGeoFilter';
import { GeoFilter } from 'types/geolocation';

export default class FallbackProvider implements FallBackLocationChain {
  async provide({ fallbackFilter }: LocationChainProviderInput): Promise<GeoFilter> {
    return fallbackFilter;
  }
}
