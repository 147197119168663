import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/appStore';
import {
  getHasUserDeniedPermission,
  getHasUserGrantedPermission,
  getHasUserPermissionPrompt,
  getNeedsUserRequest,
} from 'redux/geolocation/geolocation.slice';

const useLocationPermissionObserver = ({
  onPermissionGranted,
  onPermissionDenied,
  onPermissionPrompt,
}: {
  onPermissionGranted?: Function;
  onPermissionDenied?: Function;
  onPermissionPrompt?: Function;
}): null => {
  const hasUserDeniedPermission = useAppSelector(getHasUserDeniedPermission);
  const [isWaitingForPermission, setIsWaitingForPermission] = useState<boolean>(false);
  const needsUserRequest = useAppSelector(getNeedsUserRequest);
  const hasUserGrantedPermission = useAppSelector(getHasUserGrantedPermission);
  const hasUserPermissionPrompt = useAppSelector(getHasUserPermissionPrompt);

  useEffect(() => {
    if (!isWaitingForPermission) {
      return;
    }

    if (hasUserGrantedPermission) {
      onPermissionGranted?.();
    }

    if (hasUserDeniedPermission) {
      onPermissionDenied?.();
    }

    if (hasUserPermissionPrompt) {
      onPermissionPrompt?.();
    }

    if (hasUserGrantedPermission || hasUserDeniedPermission) {
      setIsWaitingForPermission(false);
    }
  }, [
    hasUserDeniedPermission,
    hasUserGrantedPermission,
    isWaitingForPermission,
    onPermissionDenied,
    onPermissionGranted,
    hasUserPermissionPrompt,
    onPermissionPrompt,
  ]);

  useEffect(() => {
    if (needsUserRequest) {
      setIsWaitingForPermission(true);
    }
  }, [needsUserRequest]);

  return null;
};

export default useLocationPermissionObserver;
