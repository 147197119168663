import { Taxon } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformTaxonJson } from 'lib/transforms';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export async function fetchTaxons(page = 1, itemsPerPage = 10): Promise<Taxon[] | null> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetTaxons(page, itemsPerPage));
  const res = await doFetch(endpoint);

  if (res.ok) {
    const taxonsJson = await res.json();

    const taxons: Taxon[] = taxonsJson ? taxonsJson.map(transformTaxonJson) : [];

    taxons.forEach((t) => captureTypeErrorIfInvalid('Taxon', t));

    return taxons;
  } else {
    console.warn('Error fetching taxons', res.status, res.statusText);
    return null;
  }
}

export default fetchTaxons;
