import {
  AvddIconColor,
  AvddIconName,
  AvddIconNames,
  ICON_FALLBACK,
  ICONS_ASSETS_PATH,
} from 'types/icon';
import { captureError } from 'lib/error';

export const getIconPath = ({
  iconName,
  color,
}: {
  iconName: AvddIconName;
  color: AvddIconColor;
}): string => {
  let validIconName = iconName;

  if (!AvddIconNames.hasOwnProperty(iconName)) {
    validIconName = ICON_FALLBACK;

    captureError(new Error(`unsupported icon name: ${iconName}`));
  }

  const colorSuffix = color === 'primary' ? '' : `_fill_${color}`;

  return `${ICONS_ASSETS_PATH}/${validIconName}${colorSuffix}.svg`;
};
