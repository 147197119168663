import Head from 'next/head';
import config from 'next/config';

interface Props {
  title?: string;
  hasTitleSuffix?: boolean;
}

export const DefaultHead = ({ title, hasTitleSuffix = true }: Props) => {
  const {
    publicRuntimeConfig: { defaultTitleSuffix },
  } = config();

  const fullTitle = title
    ? !hasTitleSuffix
      ? title
      : `${title} | ${defaultTitleSuffix}`
    : defaultTitleSuffix;

  if (process.env.NODE_ENV === 'development' && process.env.SHOW_SEO_WARNINGS === 'true') {
    if (fullTitle.length < 30) {
      console.warn(
        `Title "${fullTitle}" is too short according to SEO guidelines (minimum 30 characters)`
      );
    } else if (fullTitle.length > 65) {
      console.warn(
        `Title "${title}" is too long according to SEO guidelines (maximum 65 characters)`
      );
    }
  }

  return (
    <>
      <Head>
        <title>{fullTitle}</title>
        <meta property="og:title" content={fullTitle} />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
    </>
  );
};

export default DefaultHead;
