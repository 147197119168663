import { ParsedUrlQuery } from 'querystring';
import { getFallBackSortingOptionSearch } from 'lib/products/productSortingOrderOptions';
import { OrderDirection, ProductQuery, ProductsOrder } from 'types/types';
import {
  getInitialSortingItemValue,
  SortingSelection,
} from 'lib/SortingSelector/InitiallySelectedSorting';
import { Item } from 'types/ui';

export function createSearchQuery(
  sortingOrderItems: Item<SortingSelection>[],
  routerQuery?: ParsedUrlQuery,
  searchTerm?: string
): ProductQuery {
  const selectedSorting = getInitialSortingItemValue(
    sortingOrderItems,
    getFallBackSortingOptionSearch(),
    routerQuery
  );

  return {
    order: selectedSorting.option as ProductsOrder,
    direction: selectedSorting.direction as OrderDirection,
    productName: searchTerm,
  };
}
