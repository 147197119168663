import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import { getGeoCities } from 'redux/geolocation/geoProducts.slice';
import { GeoCity, GeoFilter } from 'types/geolocation';
import { setGeoFilter } from 'redux/geolocation/geolocation.slice';
import { mapGeoCityToGeoFilter } from 'lib/geolocation/mapToGeoFilter';
import { useCookies } from 'react-cookie';
import { encodeGeoFilterForCookie } from 'lib/geolocation/cityGeoFilter';
import {
  CITY_GEO_FILTER_COOKIE_MAX_AGE,
  CITY_GEO_FILTER_COOKIE_NAME,
  isNewsletterLocationSelectorVariant,
  LOCATION_SELECTOR_VARIANT,
  OPTIONAL_LOCATION,
} from 'constants/geolocation';
import { useTranslation } from 'react-i18next';
import LocationCityListItem from 'components/location/search/LocationCityListItem';
import Divider from 'components/common/Divider';
import { fetchCities } from 'redux/geolocation/geolocation.thunks';
import { trackLocationSelection } from 'modules/tracking/events/events';
import { LocationEventActions } from 'modules/tracking/tags/LocationSelectionEventTag';
import { resetAndFetchProducts } from 'redux/products/products.thunks';

interface LocationCityListProps {
  trackLocationEventAction: LocationEventActions;
  variant?: LOCATION_SELECTOR_VARIANT;
  onSelect?: (filter: GeoFilter) => void;
}

const LocationCityList = ({
  variant = LOCATION_SELECTOR_VARIANT.NAVBAR,
  onSelect,
  trackLocationEventAction,
}: LocationCityListProps) => {
  const { t } = useTranslation();
  const cities: GeoCity[] = useAppSelector(getGeoCities);
  const dispatch = useAppDispatch();
  const [, setCookie] = useCookies();

  const shouldSetGeoFilter = !isNewsletterLocationSelectorVariant(variant);

  const optionalCity = {
    ...OPTIONAL_LOCATION,
    name: t('app.ui.newsletterBanner.optional_location_label'),
  };

  useEffect(() => {
    if (cities.length) return;
    // fetch cities if not present
    dispatch(fetchCities());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectCity = (geoCity: GeoCity) => {
    const geoFilter = mapGeoCityToGeoFilter(geoCity);
    if (shouldSetGeoFilter) {
      dispatch(setGeoFilter(geoFilter));
      setCookie(CITY_GEO_FILTER_COOKIE_NAME, encodeGeoFilterForCookie(geoFilter), {
        path: '/',
        maxAge: CITY_GEO_FILTER_COOKIE_MAX_AGE,
      });

      dispatch(resetAndFetchProducts());

      onSelect?.(geoFilter);
    } else {
      onSelect?.(geoFilter);
    }

    void trackLocationSelection({
      location: `city_${geoCity.name.toLowerCase()}`,
      eventAction: trackLocationEventAction,
    });
  };

  if (!cities?.length) return null;

  return (
    <section>
      {isNewsletterLocationSelectorVariant(variant) && (
        <>
          <LocationCityListItem city={optionalCity} onSelect={selectCity} />
          <Divider />
        </>
      )}
      {cities?.map((city) => (
        <LocationCityListItem
          key={`c-${city.latitude}-${city.longitude}`}
          city={city}
          onSelect={selectCity}
        />
      ))}
    </section>
  );
};

export default LocationCityList;
