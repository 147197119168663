import { ParsedUrlQuery } from 'querystring';
import { ROUTES_WITH_TAXON_SLUG, SLUG_KEY_WORD_LOCATION } from 'constants/taxonLocationSlug';
import { getTaxonSlugByQueryParams } from 'lib/router/parsedUrlQuery/taxonMapper';
import { GeoCity, GeoFilter } from 'src/types/geolocation';
import { mapCityToSlug } from 'lib/geolocation/mapToGeoCity';
import {
  getNewLocationUrlQuery,
  removeLocationFromUrlQuery,
  shouldAddCoordinatesToUrlQuery,
} from 'lib/router/parsedUrlQuery/locationMapper';
import { mapUrlQueryParamsToString } from 'lib/router/routerHelpers';
import { GEOFILTER_TYPE } from 'constants/geolocation';

export const getPageDirectory = (routerRoute: string) => {
  return routerRoute.split('/[...').shift() || '';
};

const getBaseDirectory = (routerRoute: string): string => {
  let baseDirectory = getPageDirectory(routerRoute);

  if (shouldRemoveInFromDirectory(baseDirectory)) {
    return baseDirectory.substring(0, baseDirectory.length - SLUG_KEY_WORD_LOCATION.length);
  }

  return baseDirectory;
};

const shouldRemoveInFromDirectory = (pageDirectory: string): boolean => {
  return pageDirectory.endsWith(`/${SLUG_KEY_WORD_LOCATION}`);
};

const createLocationSlugPart = (selectedLocationSlug: string): string => {
  return `${SLUG_KEY_WORD_LOCATION}/${encodeURIComponent(selectedLocationSlug)}`;
};

const needsTaxonSlug = (routerRoute: string): boolean => {
  const pageDirectory = getPageDirectory(routerRoute);

  return (pageDirectory && ROUTES_WITH_TAXON_SLUG.includes(pageDirectory)) || false;
};

const createDirectoryFromSlugs = (
  routerRoute: string,
  queryParams: ParsedUrlQuery,
  selectedLocationSlug: string
): string => {
  const needsTaxonInSlug = needsTaxonSlug(routerRoute);
  const isEmptyLocation = selectedLocationSlug === '';

  const slugParts = [];

  const taxonSlug = getTaxonSlugByQueryParams(queryParams);

  if (needsTaxonInSlug && taxonSlug) {
    slugParts.push(taxonSlug);
  }

  if (!isEmptyLocation) {
    slugParts.push(createLocationSlugPart(selectedLocationSlug));
  }

  return slugParts.join('/');
};

/**
 * Merges two query objects, overwriting the values of the first with the values of the second.
 * If the first one has a query key.lowercase() the same as second query.key().lowercase(), override the values of the first with the second value for that key.
 * If the second object has a key that the first object doesn't have, it will be added to the first object.
 */
const mergeUrlQuery = (currentQuery: ParsedUrlQuery, newQuery: ParsedUrlQuery): ParsedUrlQuery => {
  const mergedQuery: ParsedUrlQuery = { ...currentQuery };

  Object.entries(newQuery).forEach(([key, value]) => {
    const currentQueryKey = Object.keys(currentQuery).find(
      (currentKey) => currentKey.toLowerCase() === key.toLowerCase()
    );

    mergedQuery[currentQueryKey || key] = value;
  });

  return mergedQuery;
};

const resolveQueryStringWithDivider = (query: ParsedUrlQuery): string => {
  const updatedQueryString = mapUrlQueryParamsToString(query);
  const queryDivider = updatedQueryString ? '?' : '';

  return `${queryDivider}${updatedQueryString}`;
};

export const getNewResolvedUrl = ({
  baseUrlPath,
  query,
  selectedGeoFilter,
  availableGeoCities,
  shouldAddLocation,
}: {
  baseUrlPath: string;
  query: ParsedUrlQuery;
  selectedGeoFilter: GeoFilter;
  availableGeoCities: GeoCity[];
  shouldAddLocation: boolean;
}): string => {
  if (selectedGeoFilter?.type === GEOFILTER_TYPE.IP) {
    const queryString = removeLocationFromUrlQuery(query);
    return `${baseUrlPath}${resolveQueryStringWithDivider(queryString)}`;
  }

  const newLocationSlug =
    shouldAddLocation && selectedGeoFilter.city ? mapCityToSlug(selectedGeoFilter.city) : '';

  const updatedSlugsAsDirectory = createDirectoryFromSlugs(baseUrlPath, query, newLocationSlug);

  const updatedUrlQuery = shouldAddCoordinatesToUrlQuery(
    selectedGeoFilter,
    availableGeoCities,
    shouldAddLocation
  )
    ? mergeUrlQuery(query, getNewLocationUrlQuery(selectedGeoFilter))
    : removeLocationFromUrlQuery(query);

  const directoryDivider = baseUrlPath === '/' || updatedSlugsAsDirectory === '' ? '' : '/';

  const updatedQueryString = resolveQueryStringWithDivider(updatedUrlQuery);

  return `${baseUrlPath}${directoryDivider}${updatedSlugsAsDirectory}${updatedQueryString}`;
};

export const getNewAsPath = ({
  routerRoute,
  routerQuery,
  selectedGeoFilter,
  availableGeoCities,
  shouldAddLocation,
}: {
  routerRoute: string;
  routerQuery: ParsedUrlQuery;
  selectedGeoFilter: GeoFilter;
  availableGeoCities: GeoCity[];
  shouldAddLocation: boolean;
}): string => {
  return getNewResolvedUrl({
    baseUrlPath: getBaseDirectory(routerRoute),
    query: routerQuery,
    selectedGeoFilter,
    availableGeoCities,
    shouldAddLocation,
  });
};
