import { GeoFilter } from 'types/geolocation';
import { mapCookieValuesToGeoFilter, mapGeoCityToGeoFilter } from 'lib/geolocation/mapToGeoFilter';
import { RequestWithCookies } from 'types/request';
import { CITY_GEO_FILTER_COOKIE_NAME } from 'constants/geolocation';

export const encodeGeoFilterForCookie = (geoFilter: GeoFilter) => {
  return `${geoFilter.city}:${geoFilter.query.latitude}:${geoFilter.query.longitude}:${geoFilter.label}`;
};

const decodeGeoFilterFromCookie = (cookieValue: string): GeoFilter | null => {
  const [city, latitude, longitude, cookieLabel] = cookieValue.split(':');

  if (!city || !latitude || !longitude) {
    return null;
  }

  const label = cookieLabel || city; // fallback for when cookie value was stored without label.

  return mapCookieValuesToGeoFilter({ city, latitude, longitude, label });
};

export const getGeoFilterFromCookie = (req: RequestWithCookies): GeoFilter | null => {
  const cookieValue = req.cookies[CITY_GEO_FILTER_COOKIE_NAME] || null;
  if (!cookieValue) {
    return null;
  }

  return decodeGeoFilterFromCookie(cookieValue);
};
