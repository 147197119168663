import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/appStore';
import { getClientLocation } from 'redux/geolocation/geolocation.slice';
import { GeoFilter } from 'types/geolocation';
import { GEOFILTER_TYPE } from 'constants/geolocation';
import { createGeoFilter } from 'lib/geolocation/mapToGeoFilter';

export default function useGpsGeoFilter(): { gpsFilter: GeoFilter } {
  const { t } = useTranslation();
  const clientCoordinates = useAppSelector(getClientLocation);

  const gpsFilter: GeoFilter = createGeoFilter({
    label: t('app.ui.my_location_label'),
    coordinates: clientCoordinates,
    type: GEOFILTER_TYPE.GPS,
    listHeading: t('app.ui.geofilter.type_gps.list_heading'),
    city: t('app.ui.my_location_slug'),
  });

  return { gpsFilter };
}
