import { Carousel } from 'react-responsive-carousel';
import * as React from 'react';
import useResponsive from 'hooks/useResponsive';
import Usp from './Usp';
import { useTranslation } from 'react-i18next';

export default function UspBlock({ showCarousel }: { showCarousel: boolean }) {
  const { isMd } = useResponsive();
  const { t } = useTranslation();

  if (showCarousel && !isMd) {
    return (
      <Carousel
        axis={'vertical'}
        preventMovementUntilSwipeScrollTolerance={false}
        showThumbs={false}
        infiniteLoop={true}
        showStatus={false}
        showIndicators={false}
        autoPlay={true}
        showArrows={false}
      >
        <Usp usp={t('app.ui.usp1')} />
        <Usp usp={t('app.ui.usp2')} />
        <Usp usp={t('app.ui.usp3')} />
      </Carousel>
    );
  }

  return isMd ? (
    <>
      <Usp usp={t('app.ui.usp1')} />
      <Usp usp={t('app.ui.usp2')} />
      <Usp usp={t('app.ui.usp3')} />
    </>
  ) : (
    <Usp usp={t('app.ui.usp1')} />
  );
}
