import React, { MouseEvent, useState } from 'react';
import Dialog from 'components/Dialog';
import { useTranslation } from 'react-i18next';
import { Z_INDEX } from 'constants/common';
import useLocationPermissionObserver from 'hooks/location/useLocationPermissionObserver';
import useGeoFilterTypeGpsObserver from 'hooks/location/useGeoFilterTypeGpsObserver';

const GPSUnavailableMessage = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useLocationPermissionObserver({ onPermissionDenied: () => setIsOpen(true) });

  useGeoFilterTypeGpsObserver({ onInvalidFilterSelection: () => setIsOpen(true) });

  const handleClose = (e?: MouseEvent<HTMLSpanElement>) => {
    setIsOpen(false);
    e?.stopPropagation();
  };

  return (
    <Dialog
      dialogOpen={isOpen}
      title={t('app.ui.gps_not_available')}
      titleClassName={'text-center font-semibold'}
      setDialogOpen={() => handleClose()}
      zIndex={Z_INDEX.level50}
    >
      <div className="text-center mt-4">
        <p data-testid="explanation">
          {t('app.ui.gps_not_available.we_cannot_get_current_location')}
        </p>
        <div className={`text-center mt-6`}>
          <span
            className={`w-max px-2 text-sm border border-gray-600 cursor-pointer text-gray-600 rounded-full mt-6`}
            onClick={(e) => handleClose(e)}
            data-testid="button-close"
          >
            {t('app.ui.ok')}
          </span>
        </div>
      </div>
    </Dialog>
  );
};

export default GPSUnavailableMessage;
