import { Dialog, Transition } from '@headlessui/react';
import { Dispatch, Fragment, ReactNode, SetStateAction } from 'react';
import { Z_INDEX } from 'constants/common';
import { UserIcon, XIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import { Taxon } from 'types/taxons';
import { useSession } from 'next-auth/react';
import { useRouterNavigation } from 'hooks/useRouterNavigation';
import useLocationNavigationLink from 'hooks/location/useLocationNavigationLink';
import AccountRoutes from 'constants/routes/account';
import LoginRoutes from 'constants/routes/login';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { ICON_HOME_PAGE } from 'types/icon';
import { useAppSelector } from 'redux/appStore';
import { getActiveTaxon } from 'redux/common/common.slice';
import { NavbarMobileMenuItem } from 'components/navbar/NavbarMobileMenuItem';
import { MobileMenuNavigationClick } from 'components/navbar/NavBar';

interface Props {
  taxons: Taxon[];
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  navigationClick: MobileMenuNavigationClick;
  sidebarMenu?: ReactNode;
  isHomePage?: boolean;
}

export function NavbarMobile({
  taxons,
  open,
  setOpen,
  navigationClick,
  sidebarMenu,
  isHomePage = false,
}: Props) {
  const { data: session } = useSession();
  const { navigateOnClick } = useRouterNavigation();
  const { getHref, getHomePageHref } = useLocationNavigationLink();
  const activeTaxon = useAppSelector(getActiveTaxon);
  const { t } = useTranslation();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 ${Z_INDEX.level40} flex xl:hidden`}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex flex-col w-full max-w-xs pb-12 overflow-y-auto bg-white text-t-primary shadow-xl border-t-4 border-primary">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 -m-2 rounded-md absolute top-5 right-4"
              onClick={() => setOpen(false)}
            >
              <span className="sr-only">{t('app.ui.navbar.close_menu')}</span>
              <XIcon className="w-6 h-6" aria-hidden="true" />
            </button>

            <div className="flex row flex-wrap px-5 py-4 bg-white">
              <div className="w-full flex items-center mb-5 text-center">
                <Image
                  src="/logo-turned-9-degrees.svg"
                  alt="Actievandedag logo"
                  width={32}
                  height={32}
                />
                <p className="-ml-10 w-full text-black text-center font-semibold text-lg">
                  {t('app.ui.navbar.menu')}
                </p>
              </div>

              <div className="flex w-full py-4">
                <UserIcon className="w-6 h-6" aria-hidden="true" />
                {session ? (
                  <Link href={AccountRoutes.dashboard}>
                    <span className="px-2">{t('app.ui.navbar.account')}</span>
                  </Link>
                ) : (
                  <Link href={LoginRoutes.login}>
                    <span className="px-2 cursor-pointer">{t('app.ui.navbar.login')}</span>
                  </Link>
                )}
              </div>
            </div>

            <div className="px-4 py-6 border-t border-gray-200 flex flex-col gap-6">
              {!isHomePage && (
                <NavbarMobileMenuItem
                  link={getHomePageHref}
                  isActive={false}
                  title={t('app.ui.menu.home')}
                  iconName={ICON_HOME_PAGE}
                  onClick={() => navigationClick(null)}
                />
              )}
              {taxons.map((taxon: Taxon) => (
                <NavbarMobileMenuItem
                  key={taxon.id}
                  link={getHref(taxon)}
                  title={taxon.name}
                  iconName={taxon.icon}
                  onClick={() => navigationClick(taxon)}
                  isActive={activeTaxon?.id === taxon.id}
                />
              ))}
            </div>

            <div className="px-4 py-6 space-y-6 border-t border-gray-200">
              <div className="flow-root">
                <div
                  className="block p-2 -m-2 font-medium"
                  onClick={(e) => navigateOnClick('/contact', e)}
                >
                  {t('app.ui.navbar.customer_service')}
                </div>
              </div>
              <div className="flow-root">
                <div
                  className="block p-2 -m-2 font-medium cursor-pointer"
                  onClick={(e) => navigateOnClick('/contact', e)}
                >
                  {t('app.ui.navbar.feedback')}
                </div>
              </div>
            </div>

            <div className="cursor-pointer">{sidebarMenu}</div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
