import { ParsedUrlQuery } from 'querystring';
import { SLUG_KEY_WORD_LOCATION } from 'constants/taxonLocationSlug';
import taxonApi from 'api/taxon';
import { Taxon } from 'types/taxons';
import { mapQueryParamsSlugToArray } from 'lib/router/routerHelpers';
import { SLUGS_FOR_LOCATION } from 'constants/taxon';

export const getTaxonByQueryParams = async (
  queryParams?: ParsedUrlQuery
): Promise<{ taxon: Taxon | null; yourLocationSlug: string | null }> => {
  if (!queryParams) {
    return {
      taxon: null,
      yourLocationSlug: null,
    };
  }

  const taxonSlug = getTaxonSlugByQueryParams(queryParams);

  const isInYourLocation = !!taxonSlug && SLUGS_FOR_LOCATION.includes(taxonSlug);
  const isTaxonSlug = !isInYourLocation && taxonSlug;

  const taxon = isTaxonSlug ? await taxonApi.fetchTaxonBySlug(taxonSlug) : null;

  return {
    taxon: taxon ?? null,
    yourLocationSlug: isInYourLocation ? taxonSlug : null,
  };
};

export const getTaxonSlugByQueryParams = (queryParams: ParsedUrlQuery): string | undefined => {
  const slugs = mapQueryParamsSlugToArray(queryParams);
  const locationSplitterIndex = slugs.indexOf(SLUG_KEY_WORD_LOCATION);
  const hasLocationInSlug = locationSplitterIndex > -1;

  const taxonSlugs = hasLocationInSlug ? slugs.slice(0, locationSplitterIndex) : slugs;
  const [mainCategory, subCategory] = taxonSlugs;

  return subCategory ? `${mainCategory}/${subCategory}` : mainCategory;
};
