import { PRODUCT_MINIMUM_CART_QUANTITY_DEFAULT } from 'constants/product';
import { formatDateTimeForApi } from 'lib/date';
import {
  AddToCartContext,
  BareProduct,
  Cart,
  CartDraftReservation,
  CartItem,
  CartReservation,
  Customer,
  Order,
  OrderItem,
} from 'types/types';

export function countItemsInCart(cart: Cart | null) {
  let count = 0;
  if (!cart || !cart.items) {
    return count;
  }

  for (const item of cart.items) {
    count += item.quantity;
  }

  return count;
}

export function getBuyAgainItems(order: Order): OrderItem[] {
  return order.items.filter(
    (item) => (typeof item.sellable === 'undefined' || item.sellable) && item.booking === null
  );
}

export function getSellableCartItems(cart: Cart): CartItem[] {
  return cart.items.filter((item) => (typeof item.sellable === 'undefined' ? true : item.sellable));
}

export function getUnSellableCartItems(cart: Cart): CartItem[] {
  return cart.items.filter((item) =>
    typeof item.sellable === 'undefined' ? true : !item.sellable
  );
}

export function hasNonReservableCartItems(cart: Cart): boolean {
  return (
    cart.items.filter((item) => (item.reservation ? !item.reservation.isAvailable : false)).length >
    0
  );
}

function getFirstName(cart: Cart, customer: Customer): string | null {
  if (cart.canBeShippedDigitally && cart?.billingAddress?.firstName) {
    return cart.billingAddress.firstName;
  }

  if (cart.shippingAddress && cart.shippingAddress.firstName) {
    return cart.shippingAddress.firstName;
  }

  if (customer.defaultAddress && customer.defaultAddress.firstName) {
    return customer.defaultAddress.firstName;
  }

  return customer.firstName;
}

function getLastName(cart: Cart, customer: Customer): string | null {
  if (cart.canBeShippedDigitally && cart?.billingAddress?.lastName) {
    return cart.billingAddress.lastName;
  }

  if (cart.shippingAddress && cart.shippingAddress.lastName) {
    return cart.shippingAddress.lastName;
  }

  if (customer.defaultAddress && customer.defaultAddress.lastName) {
    return customer.defaultAddress.lastName;
  }

  return customer.lastName;
}

export function canSkipQuantitySelection(
  { product: { variants } }: AddToCartContext,
  canProductBeBought: () => boolean
): boolean {
  if (variants.filter((variant) => variant.isSellable).length > 1) {
    return false;
  }

  return canProductBeBought();
}

export const buildCartReservation = ({
  date,
  time,
  comments,
  phone,
  quantity,
}: CartDraftReservation): CartReservation => {
  return {
    dateTime: formatDateTimeForApi(date, time) as string,
    phone: phone as string,
    comments: comments || '',
    quantity,
  };
};

export const computeMinimumQuantity = ({
  choosenQuantity = 0,
  product,
}: {
  choosenQuantity?: number;
  product: BareProduct | null;
}): number => {
  const allowedMininumValue =
    (product && product.minimumCartQuantity) || PRODUCT_MINIMUM_CART_QUANTITY_DEFAULT;

  return Math.max(choosenQuantity, allowedMininumValue);
};
