import Link from 'next/link';
import useResponsive from 'hooks/useResponsive';
import { useSession } from 'next-auth/react';
import { useRouterNavigation } from 'hooks/useRouterNavigation';
import useStore from 'store';
import { forwardRef } from 'react';
import { ImUser as UserIcon } from 'react-icons/im';
import AccountRoutes from 'constants/routes/account';
import LoginRoutes from 'constants/routes/login';

interface Props {
  showAccount: boolean;
}

//NB: forwardRef is needed to prevent a warning of nextJs (check https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-function-component)
const DashboardLinkIconPure = () => {
  return <UserIcon className="w-6 h-6 mx-2" />;
};
const DashboardLinkIcon = forwardRef(DashboardLinkIconPure);

export function NavbarLogin({ showAccount }: Props) {
  const { data: session } = useSession();
  const { isMobile } = useResponsive();
  const [customer] = useStore('customer');

  const { navigateOnClick } = useRouterNavigation();

  if (!showAccount || isMobile) return null;

  return (
    <div className="md:block cursor-pointer lg:flex lg:flex-1-1-2 lg:items-center lg:justify-end lg:space-x-6">
      {session ? (
        <div
          className="flex center text-gray-700 hover:text-gray-500"
          data-testid={'goToMyAccountButton'}
          onClick={(e) => {
            void navigateOnClick(AccountRoutes.dashboard, e);
          }}
        >
          <DashboardLinkIcon />

          {!isMobile && customer?.firstName && (
            <p className="flex text-sm">Hi, {customer?.firstName}</p>
          )}
        </div>
      ) : (
        <Link href={LoginRoutes.login}>
          <span className="text-sm font-medium hover:text-primary">Inloggen</span>
        </Link>
      )}
    </div>
  );
}
