import useStore from 'store';
import { useCallback } from 'react';

interface ReturnType {
  searchHistory: string[];
  updateRecentSearched: (item: string) => void;
}

const MAX_LAST_SEARCHED_ITEMS = 5;

export default function useSearchHistory(): ReturnType {
  const [history, setHistory] = useStore('searchHistory');

  const updateRecentSearched = useCallback(
    (newItem: string) => {
      if (!newItem) return;

      // filter duplicates
      let updatedHistory = [...history.filter((item) => item !== newItem)];

      // remove last item if max reached
      if (updatedHistory.length === MAX_LAST_SEARCHED_ITEMS)
        updatedHistory = updatedHistory.slice(0, MAX_LAST_SEARCHED_ITEMS - 1);

      // remove duplicates
      void setHistory([newItem, ...updatedHistory]);
    },
    [history, setHistory]
  );

  return {
    searchHistory: history,
    updateRecentSearched,
  };
}
