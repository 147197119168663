import React from 'react';
import { BiChevronRight } from 'react-icons/bi';
import { GeoCity } from 'types/geolocation';
import { useAppSelector } from 'redux/appStore';
import { getGeoFilterLabel } from 'redux/geolocation/geolocation.slice';

interface LocationCityListItemProps {
  city: GeoCity;
  onSelect: (city: GeoCity) => void;
}

const LocationCityListItem = ({ city, onSelect }: LocationCityListItemProps) => {
  const { latitude, longitude, name, slug } = city;
  const selectedLabel = useAppSelector(getGeoFilterLabel);

  return (
    <div
      className="w-full flex align-center px-2 py-1 border-none cursor-pointer"
      onClick={() => onSelect({ name, latitude, longitude, slug })}
    >
      <BiChevronRight
        className={`h-5 w-5 mx-2 ${name === selectedLabel ? 'text-purple' : 'text-gray-600'}`}
        aria-hidden="true"
      />
      <span className={name === selectedLabel ? 'text-purple' : 'text-gray-600'}>{name}</span>
    </div>
  );
};

export default LocationCityListItem;
