import {
  LocationChain,
  LocationChainProviderInput,
} from 'lib/geolocation/locationChain/LocationProviderHandler';
import { GeoFilter } from 'types/geolocation';
import { getGeoFilterFromCoordinatesAndSlug } from 'lib/geolocation/mapToGeoFilter';
import { SLUGS_FOR_LOCATION } from 'constants/taxon';

export default class FromCoordinatesAndSlugProvider implements LocationChain {
  async provide({
    locationSlug,
    coordinates,
  }: LocationChainProviderInput): Promise<GeoFilter | null> {
    if (!coordinates || !locationSlug) return null;

    const isGps = SLUGS_FOR_LOCATION.includes(locationSlug);

    return !isGps ? getGeoFilterFromCoordinatesAndSlug(coordinates, locationSlug) : null;
  }
}
