import { Taxon } from 'types/taxons';
import { useAppSelector } from 'redux/appStore';
import { selectGeoFilter } from 'redux/geolocation/geolocation.slice';
import { getGeoCities } from 'redux/geolocation/geoProducts.slice';
import { getNewResolvedUrl } from 'lib/router/asPath/routeWithLocationHelper';
import { ParsedUrlQuery } from 'querystring';
import { useCallback, useMemo } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { experiments } from 'components/experiment/experiment.registry';

export function useLocationNavigationLink(): {
  getHref: (taxon: Taxon) => string;
  getSlug: (taxon: Taxon) => string;
  addLocation: (inputString: string, urlQueryParams?: ParsedUrlQuery) => string;
  getHomePageHref: string;
} {
  const selectedGeoFilter = useAppSelector(selectGeoFilter);
  const geoCities = useAppSelector(getGeoCities);

  const isAvdd9185 = useFeatureIsOn(experiments.AVDD_9185);

  const enrichWithLocation = useCallback(
    ({
      basePath = '/',
      shouldAddLocation = true,
    }: {
      basePath?: string;
      shouldAddLocation?: boolean;
    }): string => {
      return getNewResolvedUrl({
        baseUrlPath: basePath,
        query: {},
        selectedGeoFilter,
        availableGeoCities: geoCities,
        shouldAddLocation,
      });
    },
    [geoCities, selectedGeoFilter]
  );

  const addLocation = (inputString: string): string => {
    return enrichWithLocation({ basePath: inputString });
  };

  const getSlug = (taxon: Taxon): string => {
    return enrichWithLocation({
      basePath: taxon.slug,
      shouldAddLocation: taxon.hasLocationSupport,
    });
  };

  const getHref = (taxon: Taxon): string => {
    return enrichWithLocation({
      basePath: taxon.href,
      shouldAddLocation: taxon.hasLocationSupport,
    });
  };

  const getHomePageHref = useMemo((): string => {
    if (isAvdd9185) return '/';
    return enrichWithLocation({ basePath: '/' });
  }, [enrichWithLocation, isAvdd9185]);

  return {
    getHref,
    getSlug,
    addLocation,
    getHomePageHref,
  };
}

export default useLocationNavigationLink;
