import { MdMyLocation } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import {
  getGeoFilterLabel,
  setGeoFilter,
  setLocationPermission,
  getHasUserDeniedPermission,
} from 'redux/geolocation/geolocation.slice';
import { LocationPermissionStates } from 'types/locationPermissionStates';
import { useCallback } from 'react';
import useGpsGeoFilter from 'hooks/location/useGpsGeoFilter';
import useLocationPermissionObserver from 'hooks/location/useLocationPermissionObserver';
import { CITY_GEO_FILTER_COOKIE_MAX_AGE, CITY_GEO_FILTER_COOKIE_NAME } from 'constants/geolocation';
import { encodeGeoFilterForCookie } from 'lib/geolocation/cityGeoFilter';
import { useCookies } from 'react-cookie';
import { trackLocationSelection } from 'modules/tracking/events/events';
import { LocationSelectionEventTag } from 'modules/tracking/tags/LocationSelectionEventTag';

interface MyLocationFinderProps {
  onSelect?: () => void;
}

const MyLocationFinder = ({ onSelect }: MyLocationFinderProps) => {
  const { t } = useTranslation();
  const selectedLabel = useAppSelector(getGeoFilterLabel);
  const isSelectedOption = selectedLabel === t('app.ui.my_location_label');
  const hasUserDeniedPermission = useAppSelector(getHasUserDeniedPermission);
  const dispatch = useAppDispatch();
  const [, setCookie] = useCookies();
  const { gpsFilter } = useGpsGeoFilter();

  const selectGpsFilter = useCallback(() => {
    dispatch(setGeoFilter(gpsFilter));
    setCookie(CITY_GEO_FILTER_COOKIE_NAME, encodeGeoFilterForCookie(gpsFilter), {
      path: '/',
      maxAge: CITY_GEO_FILTER_COOKIE_MAX_AGE,
    });
    onSelect?.();
  }, [dispatch, gpsFilter, onSelect]);

  useLocationPermissionObserver({
    onPermissionGranted: () => selectGpsFilter(),
    onPermissionDenied: () => onSelect?.(),
  });

  const handleClick = () => {
    dispatch(setLocationPermission(LocationPermissionStates.needsUserRequest));
    void trackLocationSelection({
      location: 'In je buurt',
      eventAction: LocationSelectionEventTag.EVENT_ACTION_LOCATION_SELECT,
    });
  };

  const getTextColor = useCallback(() => {
    if (hasUserDeniedPermission) {
      return 'text-gray-300';
    }
    if (isSelectedOption) {
      return 'text-purple';
    }
    return 'text-gray-600';
  }, [hasUserDeniedPermission, isSelectedOption]);

  return (
    <div className="w-full flex align-center mt-1 px-2 py-3 cursor-pointer" onClick={handleClick}>
      <MdMyLocation className={`h-5 w-5 mx-2 ${getTextColor()}`} aria-hidden="true" />
      <span className={getTextColor()}>{t('app.ui.my_location_label')}</span>
    </div>
  );
};

export default MyLocationFinder;
