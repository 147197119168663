import { Taxon } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformTaxonJson } from 'lib/transforms';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export async function fetchTaxonBySlug(slug: string): Promise<Taxon | null> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetTaxonBySlug(slug));
  const res = await doFetch(endpoint);

  if (res.status === 404) {
    return null;
  }

  const taxonJson = await res.json();

  const realTaxon = transformTaxonJson(taxonJson, taxonJson.parent);

  if (realTaxon) captureTypeErrorIfInvalid('Taxon', realTaxon);

  return realTaxon;
}

export default fetchTaxonBySlug;
