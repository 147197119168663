import classNames from 'classnames';
import { MenuIcon } from '@heroicons/react/outline';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  enableMobileMenu: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export function NavbarHamburgerButton({ enableMobileMenu, setOpen }: Props) {
  return (
    <button
      data-testid="hamburgerMenu"
      type="button"
      className={classNames('p-2 text-white rounded-md xl:hidden', {
        invisible: !enableMobileMenu,
      })}
      onClick={() => setOpen(true)}
    >
      <span className="sr-only">Open menu</span>
      <MenuIcon className="w-8 h-8 text-black" aria-hidden="true" />
    </button>
  );
}
