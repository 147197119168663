import {
  LocationChain,
  LocationChainProviderInput,
} from 'lib/geolocation/locationChain/LocationProviderHandler';
import { GeoFilter } from 'types/geolocation';
import { mapGeoCityToGeoFilter } from 'lib/geolocation/mapToGeoFilter';
import { getGeoCityByExactCoordinates } from 'lib/geolocation/mapToGeoCity';

export default class FromGeoCitiesAndExactCoordinatesProvider implements LocationChain {
  async provide({ geoCities, coordinates }: LocationChainProviderInput): Promise<GeoFilter | null> {
    if (!geoCities || !coordinates) return null;

    const geoCity = getGeoCityByExactCoordinates(coordinates, geoCities);

    return geoCity ? mapGeoCityToGeoFilter(geoCity) : null;
  }
}
