import { GeoFilter } from 'types/geolocation';
import { getGeoFilterFromCookie } from 'lib/geolocation/cityGeoFilter';
import { fetchClientIpCoordinates } from 'lib/geolocation/fetchClientIpCoordinates';
import { mapIpCoordinatesToGeoFilter } from 'lib/geolocation/mapToGeoFilter';
import { RequestWithCookies } from 'types/request';
import { getPublicRuntimeConfig } from 'lib/api';
import { captureError } from 'lib/error';

export const resolveFallbackGeoFilter = (req: RequestWithCookies): GeoFilter => {
  try {
    const cityGeoFilter = getGeoFilterFromCookie(req);
    if (cityGeoFilter) {
      return cityGeoFilter;
    }

    const coordinates = fetchClientIpCoordinates(req);
    return mapIpCoordinatesToGeoFilter(coordinates);
  } catch (e) {
    captureError(Error('Failed to fetch fallback geofilter coordinates from cookie'), { error: e });

    const coordinates = getPublicRuntimeConfig().defaultLocation;
    return mapIpCoordinatesToGeoFilter(coordinates);
  }
};
