import React, { useEffect, useRef } from 'react';
import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { getIsAutocompleteEnabled, setGeoFilter } from 'redux/geolocation/geolocation.slice';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import { getCityFromPlaceResult, mapPlaceResultToGeoFilter } from 'lib/geolocation/mapToGeoFilter';
import { IoMdCloseCircle } from 'react-icons/io';
import { GeoFilter } from 'types/geolocation';
import {
  CITY_GEO_FILTER_COOKIE_MAX_AGE,
  CITY_GEO_FILTER_COOKIE_NAME,
  isNewsletterLocationSelectorVariant,
  LOCATION_SELECTOR_VARIANT,
} from 'constants/geolocation';
import { language, region } from 'lib/channel/locales';
import { libraries } from 'lib/geolocation/resolveMapOptions';
import { getPublicRuntimeConfig } from 'lib/api';
import { useCookies } from 'react-cookie';
import { encodeGeoFilterForCookie } from 'lib/geolocation/cityGeoFilter';
import { trackLocationSelection } from 'modules/tracking/events/events';
import { LocationSelectionEventTag } from 'modules/tracking/tags/LocationSelectionEventTag';
import { resetAndFetchProducts } from 'redux/products/products.thunks';

interface LocationAutocompleteInputProps {
  variant?: LOCATION_SELECTOR_VARIANT;
  onChange?: (filter: GeoFilter) => void;
  close?: () => void;
}

const LocationAutocompleteInput = ({
  variant = LOCATION_SELECTOR_VARIANT.NAVBAR,
  onChange,
  close,
}: LocationAutocompleteInputProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isCurrentFilterAutocomplete = useAppSelector(getIsAutocompleteEnabled);
  const searchBoxRef = useRef<google.maps.places.SearchBox>();
  const inputRef = useRef<HTMLInputElement>(null);
  const { googleMapsApiKey } = getPublicRuntimeConfig();
  const [, setCookie] = useCookies();

  const shouldSetGeoFilter = !isNewsletterLocationSelectorVariant(variant);

  // load google maps for places api
  useJsApiLoader({ googleMapsApiKey, region, language, libraries });

  useEffect(() => {
    if (!isCurrentFilterAutocomplete && inputRef.current?.value) inputRef.current.value = '';
  }, [isCurrentFilterAutocomplete]);

  if (!global.google) return null;

  const onLoad = (ref: google.maps.places.SearchBox) => (searchBoxRef.current = ref);
  const onPlacesChanged = () => {
    const place = searchBoxRef.current?.getPlaces()?.[0];
    if (!place) return;
    const filter = mapPlaceResultToGeoFilter(place);
    if (shouldSetGeoFilter) {
      dispatch(setGeoFilter(filter));
      setCookie(CITY_GEO_FILTER_COOKIE_NAME, encodeGeoFilterForCookie(filter), {
        path: '/',
        maxAge: CITY_GEO_FILTER_COOKIE_MAX_AGE,
      });
      dispatch(resetAndFetchProducts());
      onChange?.(filter);
    } else {
      onChange?.(filter);
    }

    void trackLocationSelection({
      location:
        getCityFromPlaceResult(place)?.toLowerCase() ||
        place.name?.toLowerCase() ||
        'unknown location',
      eventAction: LocationSelectionEventTag.EVENT_ACTION_LOCATION_SELECT,
    });
  };

  return (
    <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
      <section className="py-2 px-4">
        <label
          htmlFor="autocomplete-input"
          className="block text-sm font-medium leading-6 text-gray-900 hidden"
        >
          {t('app.ui.location_search_input_placeholder')}
        </label>
        <div className="relative rounded-md shadow-sm">
          <input
            ref={inputRef}
            type="text"
            name="location-search"
            id="autocomplete-input"
            className="block w-full rounded-md border-gray-300 border py-2 pl-4 pr-10 placeholder:text-gray-400 text-ellipsis"
            placeholder={t('app.ui.location_search_input_placeholder')}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <IoMdCloseCircle
              className="h-5 w-5 text-gray-500"
              aria-hidden="true"
              onClick={(e) => close?.()}
            />
          </div>
        </div>
      </section>
    </StandaloneSearchBox>
  );
};

export default LocationAutocompleteInput;
