import {
  LocationChain,
  LocationChainProviderInput,
} from 'lib/geolocation/locationChain/LocationProviderHandler';
import { GeoCity, GeoFilter } from 'types/geolocation';
import { mapGeoCityToGeoFilter } from 'lib/geolocation/mapToGeoFilter';

export default class FromGeoCitiesProvider implements LocationChain {
  async provide({
    geoCities,
    locationSlug,
  }: LocationChainProviderInput): Promise<GeoFilter | null> {
    if (!locationSlug || !geoCities) return null;

    const foundGeoCities = geoCities.filter(
      (geoFilter: GeoCity) => geoFilter.slug === locationSlug.toLowerCase().trim()
    );

    return foundGeoCities.length ? mapGeoCityToGeoFilter(foundGeoCities[0]) : null;
  }
}
