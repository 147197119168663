import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { IoSearch as SearchIcon } from 'react-icons/io5';
import useResponsive from 'hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import { getSearchTerm } from 'lib/search';
import { MAX_SEARCH_LENGTH } from 'constants/search/search';
import { SearchHistory } from 'components/navbar/searchHistory/SearchHistory';
import useSearchHistory from 'components/navbar/searchHistory/useSearchHistory.hook';

const SearchBar = () => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isSearching, setIsSearching] = useState(false);
  const { isMd } = useResponsive();
  const router = useRouter();
  const defaultSearch = getSearchTerm(router.query);
  const [search, setSearch] = useState(defaultSearch);
  const { updateRecentSearched } = useSearchHistory();

  const doSearch = (e?: any) => {
    e?.preventDefault();

    if (search?.length) {
      // update search history
      updateRecentSearched(search);
      void router.push(`/zoeken?mq=${search}`);
      setIsSearching(false);
      return;
    }

    inputRef?.current?.focus();
  };

  const handleRecentSearchedClick = (item: string) => {
    setSearch(item);
    updateRecentSearched(item);

    void router.push(`/zoeken?mq=${item}`);
    setIsSearching(false);
  };

  // use setTimeout to catch the next render cycle
  // => give SearchHistory child comp time to update state and trigger search before unmount
  const handleOnBlur = () => setTimeout(() => setIsSearching(false), 300);

  return (
    <form
      onClick={() => setIsSearching(true)}
      onBlur={handleOnBlur}
      className={
        isMd
          ? `transition-width duration-500 ease-in-out ${isSearching ? 'w-[400px]' : 'w-80'}`
          : 'bg-white p-3'
      }
      onSubmit={doSearch}
    >
      <div className="flex items-center m-3 transition-colors duration-500 ease-in-out bg-gray-200 border border-gray-200 rounded-md md:opacity-90">
        <input
          ref={inputRef}
          type="search"
          className="w-full text-t-primary text-sm font-semibold p-2 ml-2 bg-transparent border-0 focus:outline-none shadow-0"
          placeholder={t('app.ui.search.placeholder')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          autoFocus={true}
          maxLength={MAX_SEARCH_LENGTH}
        />

        <SearchIcon onClick={doSearch} size={24} className="mr-4 cursor-pointer text-primary" />
      </div>
      {isSearching && <SearchHistory current={search} onSelect={handleRecentSearchedClick} />}
    </form>
  );
};

export default SearchBar;
