import React from 'react';
import { classNames } from 'lib/classes';

export default function ClickAwayBackdrop({
  active,
  onClick,
}: {
  active: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={classNames(
        `fixed top-0 left-0 w-full h-full z-30`,
        active ? 'bg-black/10' : 'hidden'
      )}
      onClick={onClick}
    ></div>
  );
}
