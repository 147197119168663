import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/appStore';
import { selectGeoFilter } from 'redux/geolocation/geolocation.slice';
import { GEOFILTER_TYPE } from 'constants/geolocation';

function useIsFetchingUserCoordinatesObserver({
  onStartFetching,
  onFinishFetching,
}: {
  onStartFetching: Function;
  onFinishFetching: Function;
}): void {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const geoFilter = useAppSelector(selectGeoFilter);

  const { hasClientCoordinates, isFilterTypeGps } = useMemo(() => {
    return {
      hasClientCoordinates: geoFilter?.query.latitude && geoFilter?.query?.longitude,
      isFilterTypeGps: geoFilter?.type === GEOFILTER_TYPE.GPS,
    };
  }, [geoFilter?.query.latitude, geoFilter?.query?.longitude, geoFilter?.type]);

  useEffect(() => {
    const isStartFetching = isFilterTypeGps && !hasClientCoordinates && !isFetching;

    if (isStartFetching) {
      onStartFetching?.();
      setIsFetching(true);

      return;
    }

    const isFinishFetching = hasClientCoordinates && isFetching;

    if (isFinishFetching) {
      onFinishFetching?.();
      setIsFetching(false);
    }
  }, [hasClientCoordinates, isFetching, isFilterTypeGps, onFinishFetching, onStartFetching]);
}

export default useIsFetchingUserCoordinatesObserver;
