import { AppThunk } from 'redux/appStore';
import staticContentApi from 'api/static-content';
import { Banner, Banners, ProductTaxonLocationQuery, Taxon } from 'types/types';
import { setBanners } from 'redux/taxon/taxon.slice';
import productApi from 'api/product';
import { ParsedUrlQuery } from 'querystring';
import { PAGINATION_REQUEST_DEFAULTS } from 'constants/pagination';
import { Loading } from 'constants/common';
import { createTaxonQuery } from 'lib/InfiniteObservedListQuery/ProductQuery/createTaxonQuery';
import { getSortingOrderItems } from 'lib/products/productSortingOrderOptions';
import { setPaginationState, setProductLoadingStatus } from '../products/products.slice';
import { productsPageLoaded } from 'redux/products/products.slice';
import { captureError } from 'lib/error';

export const fetchImageBannersForTaxon =
  (taxonSlug: string): AppThunk =>
  async (dispatch) => {
    const banners = await staticContentApi.fetchBannersForTaxon(taxonSlug);
    const bannerMap = new Banners(banners);
    const primary = [bannerMap.getBannerForLocation('taxon_1') as Banner | null].filter(
      Boolean
    ) as Banner[];
    const secondary = [
      bannerMap.getBannerForLocation('taxon_2') as Banner | null,
      bannerMap.getBannerForLocation('taxon_3') as Banner | null,
    ].filter(Boolean) as Banner[];

    dispatch(setBanners({ primary, secondary }));
  };

export const fetchProductsByLocation =
  (page = PAGINATION_REQUEST_DEFAULTS.page): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const itemsPerPage = state.products.pagination.itemsPerPage;
    const { latitude, longitude } = state.geolocation.filter.query;
    let query: ProductTaxonLocationQuery = {
      latitude,
      longitude,
    };
    const taxon = state.taxon.taxon;
    if (taxon) {
      query = { ...query, taxonId: taxon.id };
    }

    dispatch(setProductLoadingStatus(Loading.PENDING));
    try {
      const result = await productApi.fetchProductsByLocation({
        page,
        itemsPerPage,
        ...query,
      });

      dispatch(productsPageLoaded(result));
      dispatch(setPaginationState({ page, itemsPerPage }));
    } catch (e: unknown) {
      captureError(e as Error);
      dispatch(setProductLoadingStatus(Loading.FAILED));
    }
  };

export const fetchProducts =
  (
    page = PAGINATION_REQUEST_DEFAULTS.page,
    taxon?: Taxon,
    routerQuery?: ParsedUrlQuery
  ): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();

    const orderItems = getSortingOrderItems();
    const itemsPerPage = state.products.pagination.itemsPerPage;
    const query = createTaxonQuery(orderItems, routerQuery, taxon);

    dispatch(setProductLoadingStatus(Loading.PENDING));
    try {
      const result = await productApi.fetchProducts({
        ...query,
        page,
        itemsPerPage,
      });

      dispatch(productsPageLoaded(result));
      dispatch(setPaginationState({ page, itemsPerPage }));
    } catch (e: unknown) {
      captureError(e as Error);
      dispatch(setProductLoadingStatus(Loading.FAILED));
    }
  };
