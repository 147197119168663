import { Coordinates, GeoCity, GeoFilter } from 'src/types/geolocation';
import FromGeoCitiesAndExactCoordinatesProvider from 'lib/geolocation/locationChain/chains/FromGeoCitiesAndExactCoordinatesProvider';
import FromCoordinatesAndSlugProvider from 'lib/geolocation/locationChain/chains/FromCoordinatesAndSlugProvider';
import FromYourLocationCoordinatesProvider from 'lib/geolocation/locationChain/chains/FromYourLocationCoordinatesProvider';
import FromGeoCitiesProvider from 'lib/geolocation/locationChain/chains/FromGeoCitiesProvider';
import FallbackProvider from 'lib/geolocation/locationChain/chains/FallbackProvider';

export interface LocationChainProviderInput {
  geoCities?: GeoCity[];
  coordinates?: Coordinates;
  locationSlug?: string;
  fallbackFilter: GeoFilter;
}

export interface LocationChain {
  provide(input: LocationChainProviderInput): Promise<GeoFilter | null>;
}
export interface FallBackLocationChain extends LocationChain {
  provide(input: LocationChainProviderInput): Promise<GeoFilter>;
}

export default class LocationProviderHandler {
  private readonly locationProvider: LocationChain[];
  private readonly fallbackProvider: FallBackLocationChain;

  constructor(locationProviders: LocationChain[], fallbackProvider: FallBackLocationChain) {
    this.locationProvider = locationProviders;
    this.fallbackProvider = fallbackProvider;
  }

  async handle(input: LocationChainProviderInput): Promise<GeoFilter> {
    for (const locationProvider of this.locationProvider) {
      const location = await locationProvider.provide(input);

      if (location) return location;
    }

    return await this.fallbackProvider.provide(input);
  }
}

export const locationChainProvider = new LocationProviderHandler(
  [
    new FromGeoCitiesAndExactCoordinatesProvider(),
    new FromCoordinatesAndSlugProvider(),
    new FromYourLocationCoordinatesProvider(),
    new FromGeoCitiesProvider(),
  ],
  new FallbackProvider()
);
