import useSearchHistory from 'components/navbar/searchHistory/useSearchHistory.hook';
import { useTranslation } from 'react-i18next';
import { FaChevronRight as RightChevron } from 'react-icons/fa6';
import React, { FC } from 'react';
import classNames from 'classnames';

interface Props {
  current: string | null;
  onSelect: (item: string) => void;
}

export const SearchHistory: FC<Props> = ({ current, onSelect }) => {
  const { searchHistory } = useSearchHistory();
  const { t } = useTranslation();

  if (!searchHistory.length) return null;

  return (
    <div className="absolute z-45 bg-white px-6 py-4 rounded shadow-md w-[380px] md:w-[360px] translate-x-3 -translate-y-2 md:translate-x-5">
      <p className="font-semibold text-gray-600 pb-2">{t('app.search_history.list_heading')}</p>
      <ul>
        {searchHistory.map((item, index) => (
          <li
            key={`sh-${index}-${item}`}
            className={classNames('flex items-center h-8 py-2 cursor-pointer hover:bg-gray-100', {
              'text-purple': item === current,
            })}
            onClick={() => onSelect(item)}
          >
            <RightChevron className="text-gray-500" />
            <span className="pl-4 line-clamp-1 text-gray-500">{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
