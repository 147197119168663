import Image from 'next/image';
import { AVDD_SIZES, AvddIconColor, AvddIconName, ICON_FALLBACK, SupportedSizes } from 'types/icon';
import { getIconPath } from 'lib/Icons/icons';

interface Props {
  name: AvddIconName | null;
  size: SupportedSizes;
  color?: AvddIconColor;
}

const ICON_DIMENSIONS_BY_AVDD_SIZE: {
  [K in SupportedSizes]: number;
} = {
  [AVDD_SIZES.xs]: 16,
  [AVDD_SIZES.sm]: 20,
  [AVDD_SIZES.md]: 24,
  [AVDD_SIZES.lg]: 40,
};

export function AvddIcon({ name = ICON_FALLBACK, size, color = 'primary' }: Props) {
  const iconName = name || ICON_FALLBACK;
  const dimension = ICON_DIMENSIONS_BY_AVDD_SIZE[size];

  return (
    <Image
      src={getIconPath({ iconName, color })}
      alt={name || ''}
      width={dimension}
      height={dimension}
    />
  );
}
