import { ParsedUrlQuery } from 'querystring';

export const mapQueryParamsSlugToArray = (queryParams: ParsedUrlQuery | undefined): string[] => {
  if (!queryParams || !queryParams.slug) return [];

  return typeof queryParams.slug === 'string' ? queryParams.slug.split('/') : queryParams.slug;
};

/*
 * Returns the value of a query key from a query object as string | string[] | undefined. check of key is caseInsensitive
 */
export const getValueFromQueryKey = (
  key: string,
  queryParams: ParsedUrlQuery
): string | string[] | undefined => {
  const queryKey = Object.keys(queryParams).find(
    (queryKey) => queryKey.toLowerCase() === key.toLowerCase()
  );

  return queryKey ? queryParams[queryKey] : undefined;
};

/**
 * Makes a valid url out of the ParsedUrlQuery, including making the queryParams.slug array to directory string
 */
export const mapUrlQueryParamsToString = (queryParams: ParsedUrlQuery): string => {
  const { slug, ...restQueryParams } = queryParams;

  return Object.entries(restQueryParams)
    .map(([key, value]) => {
      if (value === undefined || value === null) return '';

      return `${key}=${value}`;
    })
    .filter((queryParam) => queryParam !== '')
    .join('&');
};
