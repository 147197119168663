import { createGeoFilter } from 'lib/geolocation/mapToGeoFilter';
import { Coordinates, GeoFilter } from 'types/geolocation';
import { GEOFILTER_TYPE } from 'constants/geolocation';

export const getYourLocationGeoFilterFromCoordinates = (coordinates: Coordinates): GeoFilter => {
  if (!coordinates.latitude || !coordinates.longitude) {
    throw new Error('Error in getPlaceFromCoordinates: missing required params');
  }

  return createGeoFilter({
    label: 'In de buurt',
    coordinates: {
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
    },
    type: GEOFILTER_TYPE.AUTOCOMPLETE,
    listHeading: 'de buurt',
    city: 'bij-mij-in-buurt',
  });
};
