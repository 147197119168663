import Link from 'next/link';
import { AvddIcon } from 'components/taxons/AvddIcon';
import { AVDD_SIZES, AvddIconName } from 'types/icon';
import { capitalizeFirstLetter } from 'lib/strings';
import classNames from 'classnames';
import { MobileMenuNavigationClick } from 'components/navbar/NavBar';

type Props = {
  link: string;
  title: string;
  onClick: () => void;
  iconName: AvddIconName | null;
  isActive?: boolean;
};

export const NavbarMobileMenuItem = ({ title, link, iconName, isActive, onClick }: Props) => {
  return (
    <Link
      href={link}
      onClick={onClick}
      className={classNames('flex', {
        'text-primary': isActive,
        'text-t-primary': !isActive,
      })}
      prefetch={false}
    >
      <span className={'pr-4'}>
        <AvddIcon name={iconName} size={AVDD_SIZES.md} />
      </span>
      {capitalizeFirstLetter(title)}
    </Link>
  );
};
