import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { GeoCity } from 'src/types/geolocation';
import { decodePostalAddress } from 'src/lib/address/encryptPostalAddress';
import { mapCityToSlug } from 'lib/geolocation/mapToGeoCity';

export const POST_CODE_QUERY_PARAM_NAME = 'pc';

export default function usePostalAddress(): GeoCity | null {
  const router = useRouter();
  const { query } = router;

  return useMemo(() => {
    if (query[POST_CODE_QUERY_PARAM_NAME] === undefined) {
      return null;
    }

    const address = decodePostalAddress(query[POST_CODE_QUERY_PARAM_NAME] as string);
    if (address === null) {
      return null;
    }

    return {
      name: `${address.city} (${address.postcode})`,
      longitude: address.longitude,
      latitude: address.latitude,
      slug: mapCityToSlug(address.city),
    };
  }, [query]);
}
