import { Coordinates, CoordinatesAsUrlQuery, GeoCity, GeoFilter } from 'types/geolocation';
import { ParsedUrlQuery } from 'querystring';
import { SLUG_KEY_WORD_LOCATION } from 'constants/taxonLocationSlug';
import { getValueFromQueryKey, mapQueryParamsSlugToArray } from 'lib/router/routerHelpers';
import {
  URL_QUERY_CENTERPOINT_LATITUDE,
  URL_QUERY_CENTERPOINT_LONGITUDE,
} from 'constants/geolocation';
import { getGeoCityByExactCoordinates } from 'lib/geolocation/mapToGeoCity';
import validation from 'lib/validation';
import { locationChainProvider } from 'lib/geolocation/locationChain/LocationProviderHandler';

export const getLocationByQueryParams = async (
  fallbackFilter: GeoFilter,
  queryParams?: ParsedUrlQuery,
  allowedCities?: GeoCity[]
): Promise<GeoFilter> => {
  if (!queryParams) {
    return await locationChainProvider.handle({ fallbackFilter });
  }

  const { locationSlug, centerCoordinates } = getLocationParamsByQueryParams(queryParams);

  return await locationChainProvider.handle({
    geoCities: allowedCities,
    coordinates: centerCoordinates,
    locationSlug,
    fallbackFilter,
  });
};

const createCoordinates = (latitude: number, longitude: number): Coordinates => {
  return {
    latitude,
    longitude,
  } as Coordinates;
};

const getCoordinatesFromUrlQuery = (
  latitudeKey: string,
  longitudeKey: string,
  queryParams: ParsedUrlQuery
): Coordinates | undefined => {
  const latitudeInQuery = getValueFromQueryKey(latitudeKey, queryParams);
  const latitude = Array.isArray(latitudeInQuery) ? latitudeInQuery[0] : latitudeInQuery;

  const longitudeInQuery = getValueFromQueryKey(longitudeKey, queryParams);
  const longitude = Array.isArray(longitudeInQuery) ? longitudeInQuery[0] : longitudeInQuery;

  if (!latitude || !longitude) {
    return undefined;
  }

  const hasValidCoordinates =
    validation.isValidCoordinateInput('latitude', latitude) &&
    validation.isValidCoordinateInput('longitude', longitude);

  if (!hasValidCoordinates) {
    return undefined;
  }

  return createCoordinates(parseFloat(latitude), parseFloat(longitude));
};

const generateQueryForCoordinates = (
  latitudeKey: string,
  longitudeKey: string,
  coordinates: Coordinates
): CoordinatesAsUrlQuery => {
  const query: CoordinatesAsUrlQuery = {};

  if (coordinates.latitude !== null && coordinates.longitude !== null) {
    query[latitudeKey] = encodeURIComponent(coordinates.latitude);
    query[longitudeKey] = encodeURIComponent(coordinates.longitude);
  }

  return query;
};

export const getLocationParamsByQueryParams = (
  queryParams: ParsedUrlQuery
): {
  locationSlug?: string;
  centerCoordinates?: Coordinates;
} => {
  const slugs = mapQueryParamsSlugToArray(queryParams);
  const locationSplitterIndex = slugs.indexOf(SLUG_KEY_WORD_LOCATION as never);
  const hasLocation = locationSplitterIndex > -1;
  const locationNameIndex = locationSplitterIndex + 1;

  return {
    locationSlug: hasLocation ? slugs[locationNameIndex] : undefined,
    centerCoordinates: getCoordinatesFromUrlQuery(
      URL_QUERY_CENTERPOINT_LATITUDE,
      URL_QUERY_CENTERPOINT_LONGITUDE,
      queryParams
    ),
  };
};

export const getNewLocationUrlQuery = (selectedGeoFilter: GeoFilter): CoordinatesAsUrlQuery => {
  return generateQueryForCoordinates(
    URL_QUERY_CENTERPOINT_LATITUDE,
    URL_QUERY_CENTERPOINT_LONGITUDE,
    selectedGeoFilter.query
  );
};

export const removeLocationFromUrlQuery = (query: ParsedUrlQuery): ParsedUrlQuery => {
  const newQuery = { ...query };

  Object.keys(newQuery).forEach((key) => {
    if (
      key.toLowerCase() === URL_QUERY_CENTERPOINT_LATITUDE.toLowerCase() ||
      key.toLowerCase() === URL_QUERY_CENTERPOINT_LONGITUDE.toLowerCase()
    ) {
      delete newQuery[key];
    }
  });

  return newQuery;
};

export const shouldAddCoordinatesToUrlQuery = (
  selectedGeoFilter: GeoFilter,
  availableGeoCities: GeoCity[],
  shouldAddLocation: boolean
): boolean => {
  if (!shouldAddLocation) {
    return false;
  }

  return getGeoCityByExactCoordinates(selectedGeoFilter.query, availableGeoCities) === undefined;
};
