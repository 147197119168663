import * as React from 'react';
import { classNames } from 'lib/classes';
import { ShoppingCartIcon } from '@heroicons/react/outline';
import useStore from '../../store';
import { countItemsInCart } from 'lib/cart/cart';
import { useRouterNavigation } from 'hooks/useRouterNavigation';

export default function CartButton() {
  const [cart] = useStore('cart');
  const { navigateOnClick } = useRouterNavigation();

  return (
    <div
      className="relative flex pl-2 pr-4 md:pl-4 cursor-pointer"
      data-testid={'goToCartButton'}
      onClick={(e) => navigateOnClick('/cart', e)}
    >
      <ShoppingCartIcon
        className="flex-shrink-0 w-6 h-6 text-gray-900 hover:text-gray-500"
        aria-hidden="true"
      />
      <span
        suppressHydrationWarning
        className={classNames(
          'absolute ml-4 inline-flex items-center justify-center p-2 text-xs font-semibold leading-none bg-primary text-white rounded-full',
          countItemsInCart(cart) > 9 ? 'w-6 h-6' : 'w-4 h-4'
        )}
      >
        {countItemsInCart(cart)}
      </span>
    </div>
  );
}
