import classNames from 'classnames';
import { SearchIcon } from '@heroicons/react/outline';
import useResponsive from 'hooks/useResponsive';
import SearchBar from 'components/navbar/SearchBar';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import ClickAwayBackdrop from 'components/common/ClickAwayBackdrop';
import { useTranslation } from 'react-i18next';

interface Props {
  showSearch: boolean;
}

export function NavbarMobileSearch({ showSearch }: Props) {
  const { isMobile } = useResponsive();
  const router = useRouter();
  const { t } = useTranslation();
  const [isSearching, setIsSearching] = useState(
    router.query.search && router.query.search.length > 0
  );

  const closeSearch = () => setIsSearching(!isSearching);

  if (!isMobile) return null;

  return (
    <>
      <div
        className={classNames('flex items-center justify-center h-16', {
          invisible: !showSearch,
        })}
      >
        <div onClick={closeSearch} className="text-gray-400 lg:hidden hover:text-gray-500">
          <span className="sr-only">{t('app.ui.navbar.search')}</span>
          <SearchIcon className="w-6 h-6 mx-2 text-black" aria-hidden="true" />
        </div>
      </div>
      {isSearching && (
        <>
          <div className="absolute z-40 w-full right-0 top-15 shadow bg-gray-200 rounded-md">
            <SearchBar />
          </div>
          <ClickAwayBackdrop active={isSearching} onClick={closeSearch} />
        </>
      )}
    </>
  );
}
