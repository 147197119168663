import { ReactNode, useState } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import Image from 'next/image';
import { Taxon } from 'types/types';
import SearchBar from './SearchBar';
import { useTranslation } from 'react-i18next';
import CartButton from 'components/cart/CartButton';
import useResponsive from 'hooks/useResponsive';
import logo from 'public/logo.svg';
import config from 'next/config';
import LocationSearchComboBox from 'components/location/search/LocationSearchComboBox';
import { Language, Region } from 'constants/locales';
import UspBlock from 'components/usp/UspBlock';
import useLocationNavigationLink from 'hooks/location/useLocationNavigationLink';
import { taxonClick } from 'modules/tracking/events/events';
import { NavbarMobile } from 'components/navbar/NavbarMobile';
import { useNavbarStickyScroll } from 'components/navbar/navbarStickyScroll.hook';
import { NavbarHamburgerButton } from 'components/navbar/NavbarHamburgerButton';
import { NavbarMobileSearch } from 'components/navbar/NavbarMobileSearch';
import classNames from 'classnames';
import { NavbarLogin } from 'components/navbar/NavbarLogin';

interface Props {
  isHomepage?: boolean;
  showSearch?: boolean;
  taxons?: Taxon[];
  showAccount?: boolean;
  showCart?: boolean;
  sidebarMenu?: ReactNode;
  enableLocationSearch?: boolean;
  showUsps?: boolean;
  enableMobileMenu?: boolean;
  showNavbar?: boolean;
}

export type MobileMenuNavigationClick = (taxon: Taxon | null) => void;

export function Navbar({
  isHomepage = false,
  showSearch = true,
  taxons = [],
  showAccount = true,
  showCart = true,
  showNavbar = true,
  sidebarMenu = <></>,
  enableLocationSearch = false,
  showUsps = true,
  enableMobileMenu = true,
}: Props) {
  const [open, setOpen] = useState(false);
  const { isLg } = useResponsive();
  const { t } = useTranslation();
  const { publicRuntimeConfig } = config();
  const { getHomePageHref } = useLocationNavigationLink();
  const { stickyClassName } = useNavbarStickyScroll();

  const closeNavbar = () => setOpen(false);

  const navigationClick: MobileMenuNavigationClick = async (taxon) => {
    closeNavbar();
    taxon && (await taxonClick({ taxon }));
  };

  return (
    <>
      {showUsps && (
        <div
          className={`uspBlockHeader w-full py-2 font-semibold text-black bg-primary-light border-primary border-b-2 ${stickyClassName}`}
        >
          <div className="flex container items-center justify-around font-semibold text-xs">
            <UspBlock showCarousel={isHomepage} />
          </div>
        </div>
      )}

      <div className={`stickyNavbar bg-white ${stickyClassName}`}>
        <NavbarMobile
          taxons={taxons}
          open={open}
          setOpen={setOpen}
          navigationClick={navigationClick}
          sidebarMenu={sidebarMenu}
          isHomePage={isHomepage}
        />

        <header
          className={classNames('relative bg-white', {
            shadow: !showNavbar,
          })}
        >
          <nav aria-label="Top" className="container mx-auto">
            <div className="border-gray-200">
              <div className="flex items-center justify-center h-16 md:h-20">
                <NavbarHamburgerButton enableMobileMenu={enableMobileMenu} setOpen={setOpen} />

                <NavbarMobileSearch showSearch={showSearch} />

                <div className="flex w-auto mr-auto ml-auto md:ml-0" data-testid={'mainLogo'}>
                  <Link href={getHomePageHref} className="flex center" prefetch={false}>
                    <Image
                      className="cursor-pointer"
                      src={logo}
                      alt={t('app.ui.logo.alt')}
                      width={isLg ? 250 : 180}
                      height={isLg ? 60 : 50}
                      priority
                      quality={isLg ? 100 : 75}
                    />
                  </Link>
                </div>

                <div className="flex items-center md:ml-auto">
                  {enableLocationSearch && (
                    <LocationSearchComboBox
                      region={publicRuntimeConfig.isNL ? Region.NL : Region.BE}
                      language={Language.NL}
                    />
                  )}

                  {showSearch ? (
                    <div className="flex">
                      <div className="hidden lg:flex mr-4">
                        <SearchBar />
                      </div>
                      <div className="flex items-center justify-center h-20 w-6 mx-2 invisible lg:hidden" />
                    </div>
                  ) : (
                    <div className="p-2 invisible lg:hidden">
                      <SearchIcon className="w-6 h-6 mx-2" aria-hidden="true" />
                    </div>
                  )}

                  <NavbarLogin showAccount={showAccount} />

                  {showCart && <CartButton />}
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </>
  );
}

export default Navbar;
