import { AppThunk } from 'redux/appStore';
import { createSearchQuery } from 'lib/InfiniteObservedListQuery/ProductQuery/createSearchQuery';
import { getSortingOrderItems } from 'lib/products/productSortingOrderOptions';
import { ParsedUrlQuery } from 'querystring';
import productApi from 'api/product';
import { PAGINATION_REQUEST_DEFAULTS } from 'constants/pagination';
import {
  productsPageLoaded,
  resetProductsState,
  setPaginationState,
  setProductLoadingStatus,
} from 'redux/products/products.slice';
import { Loading } from 'constants/common';
import { captureError } from 'lib/error';
import { fetchProductsByLocation } from 'redux/taxon/taxon.thunks';

export const searchProducts =
  (
    searchTerm: string,
    page = PAGINATION_REQUEST_DEFAULTS.page,
    routerQuery?: ParsedUrlQuery
  ): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();

    const sortingOrderItems = getSortingOrderItems(true);
    const query = createSearchQuery(sortingOrderItems, routerQuery, searchTerm);
    const itemsPerPage = state.products.pagination.itemsPerPage;

    dispatch(setProductLoadingStatus(Loading.PENDING));
    try {
      const result = await productApi.fetchProductsSearch({
        ...query,
        productName: searchTerm,
        page,
        itemsPerPage,
      });

      dispatch(productsPageLoaded(result));
      dispatch(setPaginationState({ page, itemsPerPage }));
    } catch (e: unknown) {
      captureError(e as Error);
      dispatch(setProductLoadingStatus(Loading.FAILED));
    }
  };

export const resetAndFetchProducts = (): AppThunk => async (dispatch) => {
  dispatch(resetProductsState());
  dispatch(fetchProductsByLocation());
};
