import { Coordinates } from 'types/geolocation';
import { getPublicRuntimeConfig } from 'lib/api';
import { IncomingMessage } from 'http';

const CLOUD_FRONT_LATITUDE_HEADER = 'CloudFront-Viewer-Latitude';
const CLOUD_FRONT_LONGITUDE_HEADER = 'CloudFront-Viewer-Longitude';

export const fetchClientIpCoordinates = (req: IncomingMessage): Coordinates => {
  const cloudFrontLatitude =
    req.headers.cloudfrontViewerLatitude ||
    req.headers[CLOUD_FRONT_LATITUDE_HEADER] ||
    req.headers[CLOUD_FRONT_LATITUDE_HEADER.toLowerCase()];
  const cloudFrontLongitude =
    req.headers.cloudfrontViewerLongitude ||
    req.headers[CLOUD_FRONT_LONGITUDE_HEADER] ||
    req.headers[CLOUD_FRONT_LONGITUDE_HEADER.toLowerCase()];

  if (!cloudFrontLatitude || !cloudFrontLongitude) {
    return getPublicRuntimeConfig().defaultLocation;
  }

  return {
    latitude: parseFloat(cloudFrontLatitude.toString()),
    longitude: parseFloat(cloudFrontLongitude.toString()),
  };
};
