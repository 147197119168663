import { useEffect, useState } from 'react';

type stickyClasses = 'sticky shadow' | 'stickyHidden' | 'notSticky';

export const useNavbarStickyScroll = () => {
  const [stickyClassName, setStickyClassName] = useState<stickyClasses>('notSticky');

  useEffect(() => {
    (function () {
      const doc = document.documentElement;
      const w = window;

      const uspElementBottom = 45;
      const headerElementBottom = 121;

      let prevViewPortTop = w.scrollY || doc.scrollTop;
      let curViewPortTop;
      let isScrollingUp = false;

      const checkScroll = function () {
        curViewPortTop = w.scrollY || doc.scrollTop;
        isScrollingUp = curViewPortTop < prevViewPortTop;

        const bottomPoint = isScrollingUp ? uspElementBottom : headerElementBottom;
        const shouldBeAbsolute = curViewPortTop > bottomPoint;

        const shouldBeRelative = !shouldBeAbsolute && isScrollingUp;
        const shouldBeAbsoluteVisible = shouldBeAbsolute && isScrollingUp;
        const shouldBeAbsoluteHidden = shouldBeAbsolute && !isScrollingUp;

        if (shouldBeRelative) setStickyClassName('notSticky');
        if (shouldBeAbsoluteVisible) setStickyClassName('sticky shadow');
        if (shouldBeAbsoluteHidden) setStickyClassName('stickyHidden');

        prevViewPortTop = curViewPortTop;
      };

      window.addEventListener('scroll', checkScroll);

      return () => {
        // cleanup scroll listener
        window.removeEventListener('scroll', checkScroll);
      };
    })();
  }, []);

  return { stickyClassName };
};
