import { ParsedUrlQuery } from 'querystring';
import { getFallBackSortingOptionCategory } from 'lib/products/productSortingOrderOptions';
import { OrderDirection, ProductQuery, ProductsOrder, SortingSelection, Taxon } from 'types/types';
import { getInitialSortingItemValue } from 'lib/SortingSelector/InitiallySelectedSorting';
import { Item } from 'types/ui';

export function createTaxonQuery(
  sortingOrderItems: Item<SortingSelection>[],
  routerQuery?: ParsedUrlQuery,
  taxon?: Taxon
): ProductQuery {
  const selectedSorting = getInitialSortingItemValue(
    sortingOrderItems,
    getFallBackSortingOptionCategory(),
    routerQuery
  );

  return {
    order: selectedSorting.option as ProductsOrder,
    direction: selectedSorting.direction as OrderDirection,
    taxonSlug: taxon?.slug || undefined,
  };
}
