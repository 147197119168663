import React, { MouseEvent } from 'react';
import { MdOutlineLocationOn as LocationPinIcon } from 'react-icons/md';

import { IoMdCloseCircle } from 'react-icons/io';
import { FaCaretDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/appStore';
import { getGeoFilterLabel } from 'redux/geolocation/geolocation.slice';
import ActivityIndicator from 'src/components/ActivityIndicator';

interface LocationSearchLabelProps {
  isDropdownOpen: boolean;
  label?: string | null;
  isLoading?: boolean;
  onClick?: () => void;
  close: () => void;
}

const LocationSearchLabel = ({
  isDropdownOpen,
  label,
  onClick,
  close,
  isLoading = false,
}: LocationSearchLabelProps) => {
  const { t } = useTranslation();
  const displayed = useAppSelector(getGeoFilterLabel);
  const displayedLabel = label ?? displayed;

  const resetInput = (e: MouseEvent) => {
    e.stopPropagation();
    close();
  };

  return (
    <div
      className="cursor-pointer relative w-full md:w-64 rounded-md bg-gray-200 shadow-sm border-2 md:border-0 py-2 pl-8 pr-10 text-ellipsis opacity-70"
      onClick={onClick}
    >
      <div className="absolute inset-y-0 left-0 flex items-center pl-1">
        <LocationPinIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
      </div>
      <label className="block text-sm leading-6 text-gray-500">
        {displayedLabel || t('app.ui.location_search_input_placeholder')}
      </label>
      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
        {isLoading && <ActivityIndicator />}
        {isDropdownOpen ? (
          <IoMdCloseCircle
            className="h-5 w-5 text-gray-500"
            aria-hidden="true"
            onClick={(e) => resetInput(e)}
          />
        ) : (
          <FaCaretDown className="h-5 w-5 text-gray-500" aria-hidden="true" onClick={onClick} />
        )}
      </div>
    </div>
  );
};

export default LocationSearchLabel;
