export const AvddIconNames = {
  icn_alledeal: true,
  icn_avondjeuit: true,
  icn_bestsellers: true,
  icn_dagjeuit: true,
  icn_elephant: true,
  icn_etenendrinken: true,
  icn_fallback: true,
  icn_feest: true,
  icn_ferriswheel: true,
  icn_home: true,
  icn_hotelsenvakanties: true,
  icn_kados: true,
  icn_kerst: true,
  icn_nieuwjaar: true,
  icn_passen: true,
  icn_producten: true,
  icn_rollercoaster: true,
  icn_saunaenbeauty: true,
  icn_share_web: true,
  icn_sun: true,
  icn_tuinenbuiten: true,
  icn_valentijn: true,
} as const;

export type AvddIconName = keyof typeof AvddIconNames;

export const AVDD_SIZES = {
  xs: 'xs' as const,
  sm: 'sm' as const,
  md: 'md' as const,
  lg: 'lg' as const,
} as const;

export type SupportedSizes = (typeof AVDD_SIZES)[keyof typeof AVDD_SIZES];

export type AvddIconColor = 'primary' | 'white';

export const ICON_HOME_PAGE = 'icn_home';
export const ICON_FALLBACK = 'icn_fallback';

export const ICONS_ASSETS_PATH = '/avddIcons';
