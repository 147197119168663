export const AccountRoutes = {
  addressList: '/account/address',
  addressCreate: '/account/address/create',
  addressEdit: (id: number): string => `/account/address/${id}`,
  dashboard: '/account/dashboard',
  returnableItems: '/account/retourneren',
  returnedItems: '/account/geretourneerd',
  requestReturn: '/account/retour-aanmelden',
  order: (orderTokenValue: string): string => `/account/orders/${orderTokenValue}`,
};

export default AccountRoutes;
