import { Libraries } from '@react-google-maps/api';
import { DESKTOP_MAP_HEIGHT, MOBILE_MAP_HEIGHT, defaultMapOptions } from 'constants/geolocation';

export const resolveMapOptions = (
  isMobile: boolean,
  options: google.maps.MapOptions
): google.maps.MapOptions => ({
  ...defaultMapOptions,
  ...options,
  zoomControl: !isMobile,
  zoomControlOptions: { position: isMobile ? 8.0 : 3.0 }, // google.maps.ControlPosition
  keyboardShortcuts: !isMobile,
});

export const libraries: Libraries = ['places'];

export const useMapContainerStyle = (isMobile: boolean, isFullScreen: boolean) => ({
  width: '100%',
  height: isFullScreen ? '100%' : isMobile ? MOBILE_MAP_HEIGHT : DESKTOP_MAP_HEIGHT,
});
