import { Item } from 'types/ui';
import { ParsedUrlQuery } from 'querystring';

export interface SortingSelection {
  option: string;
  direction: string;
}

export function getInitialSortingOption(
  allowedOptions: string[],
  defaultSortingOption: string,
  optionInUrl?: string
): string {
  return optionInUrl && allowedOptions.includes(optionInUrl) ? optionInUrl : defaultSortingOption;
}

export function getInitialSortingDirection(
  allowedDirections: string[],
  defaultSortingDirection: string,
  sortingDirectionInUrl?: string
): string {
  const fallBackDirection =
    defaultSortingDirection && allowedDirections.includes(defaultSortingDirection)
      ? defaultSortingDirection
      : allowedDirections[0];

  return sortingDirectionInUrl && allowedDirections.includes(sortingDirectionInUrl)
    ? sortingDirectionInUrl
    : fallBackDirection;
}

export function getOptionFromRouterQuery(routerQuery?: ParsedUrlQuery): string | undefined {
  return typeof routerQuery?.order === 'string' ? routerQuery?.order : undefined;
}

export function getDirectionFromRouterQuery(routerQuery?: ParsedUrlQuery): string | undefined {
  return typeof routerQuery?.direction === 'string' ? routerQuery?.direction : undefined;
}

export function getInitialSortingItem(
  sortingOptions: Item<SortingSelection>[],
  fallBackSelection: SortingSelection,
  routerQuery?: ParsedUrlQuery
): Item<SortingSelection> {
  const optionInUrl = getOptionFromRouterQuery(routerQuery);
  const directionInUrl = getDirectionFromRouterQuery(routerQuery);

  const option = getInitialSortingOption(
    sortingOptions.map((option: Item<SortingSelection>): string => option.value.option),
    fallBackSelection.option,
    optionInUrl
  );

  const direction = getInitialSortingDirection(
    sortingOptions.map((option: Item<SortingSelection>): string => option.value.direction),
    fallBackSelection.direction,
    directionInUrl
  );

  const selection = sortingOptions.find(
    (item) => item.value.option === option && item.value.direction === direction
  );

  return selection ? selection : sortingOptions[0];
}

export function getInitialSortingItemValue(
  sortingItems: Item<SortingSelection>[],
  sortingFallBackSelection: SortingSelection,
  routerQuery?: ParsedUrlQuery
): SortingSelection {
  const selectedItem = getInitialSortingItem(sortingItems, sortingFallBackSelection, routerQuery);

  return selectedItem.value;
}
