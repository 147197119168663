import React, { useCallback, useEffect, useState } from 'react';
import { Language, Region } from 'constants/locales';
import DropdownPanel from 'components/common/DropdownPanel';
import LocationCityList from 'components/location/search/LocationCityList';
import MyLocationFinder from 'components/location/search/MyLocationFinder';
import LocationSearchLabel from 'components/location/search/LocationSearchLabel';
import LocationAutocompleteInput from 'components/location/filters/LocationAutocompleteInput';
import { useJsApiLoader } from '@react-google-maps/api';
import { libraries } from 'lib/geolocation/resolveMapOptions';
import config from 'next/config';
import GPSUnavailableMessage from 'components/location/filters/GPSUnavailableMessage';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import productApi from 'api/product';
import { getGeoCities, setGeoCities } from 'redux/geolocation/geoProducts.slice';
import {
  selectGeoFilter,
  setClientLocation,
  setGeoFilter,
} from 'redux/geolocation/geolocation.slice';
import useGeoFilterTypeGpsObserver from 'hooks/location/useGeoFilterTypeGpsObserver';
import { GEOFILTER_TYPE, initialClientCoordinates } from 'constants/geolocation';
import useIsFetchingUserCoordinatesObserver from 'hooks/location/useIsFetchingUserCoordinatesObserver';
import ClickAwayBackdrop from 'components/common/ClickAwayBackdrop';
import { createGeoFilter } from 'lib/geolocation/mapToGeoFilter';
import { LocationSelectionEventTag } from 'modules/tracking/tags/LocationSelectionEventTag';

interface LocationSearchComboBoxProps {
  region: Region;
  language: Language;
}

const LocationSearchComboBox = ({ region, language }: LocationSearchComboBoxProps) => {
  const { publicRuntimeConfig } = config();
  const cities = useAppSelector(getGeoCities);
  const dispatch = useAppDispatch();
  const selectedGeoFilter = useAppSelector(selectGeoFilter);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  // load google maps places api
  useJsApiLoader({
    googleMapsApiKey: publicRuntimeConfig.googleMapsApiKey,
    region: region ?? Region.NL,
    language: language ?? Language.NL,
    libraries,
  });

  // if user has blocked browser permissions whilst the filter is set to GPS, set filter to city closest to last client location.
  useGeoFilterTypeGpsObserver({
    onInvalidFilterSelection: useCallback(() => {
      const setFallbackFilter = (): void => {
        const fallbackFilter = createGeoFilter({
          label: selectedGeoFilter.city,
          type: GEOFILTER_TYPE.AUTOCOMPLETE,
          coordinates: selectedGeoFilter.query,
          listHeading: selectedGeoFilter.city,
          city: selectedGeoFilter.city,
        });

        dispatch(setGeoFilter(fallbackFilter));
        dispatch(setClientLocation(initialClientCoordinates));
      };

      setFallbackFilter();
    }, [dispatch, selectedGeoFilter.city, selectedGeoFilter.query]),
  });

  useIsFetchingUserCoordinatesObserver({
    onStartFetching: () => {
      setIsLoading(true);
    },
    onFinishFetching: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    // fallback. Cities can be loaded in the backend.
    if (cities?.length) return;

    const fetchCitiesList = async () => {
      const cities = await productApi.fetchProductCities();
      dispatch(setGeoCities(cities));
    };

    void fetchCitiesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDropdown = () => setIsDropdownOpen(true);
  const closeDropdown = () => setIsDropdownOpen(false);

  return (
    <section className="pt-0 pb-4 md:pb-2 md:py-2 px-2 relative">
      <LocationSearchLabel
        isDropdownOpen={isDropdownOpen}
        isLoading={isLoading}
        onClick={openDropdown}
        close={closeDropdown}
      />
      <ClickAwayBackdrop active={isDropdownOpen} onClick={closeDropdown} />
      <DropdownPanel show={isDropdownOpen}>
        <LocationAutocompleteInput onChange={closeDropdown} close={closeDropdown} />
        <MyLocationFinder onSelect={closeDropdown} />
        <LocationCityList
          onSelect={closeDropdown}
          trackLocationEventAction={LocationSelectionEventTag.EVENT_ACTION_LOCATION_SELECT}
        />
      </DropdownPanel>
      <GPSUnavailableMessage />
    </section>
  );
};

export default LocationSearchComboBox;
