import {
  LocationChain,
  LocationChainProviderInput,
} from 'lib/geolocation/locationChain/LocationProviderHandler';
import { GeoFilter } from 'types/geolocation';
import { getYourLocationGeoFilterFromCoordinates } from 'lib/geolocation/getYourLocationGeoFilterFromCoordinates';

export default class FromYourLocationCoordinatesProvider implements LocationChain {
  async provide({ coordinates }: LocationChainProviderInput): Promise<GeoFilter | null> {
    if (!coordinates) return null;

    return getYourLocationGeoFilterFromCoordinates(coordinates);
  }
}
