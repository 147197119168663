import React, { Fragment, ReactElement } from 'react';
import { Transition } from '@headlessui/react';
import { classNames } from 'lib/classes';
import { isSafari } from 'lib/browser';

interface DropdownPanelProps {
  show: boolean;
  children?: ReactElement | ReactElement[] | JSX.Element | string | null;
}

const DropdownPanel = ({ show, children }: DropdownPanelProps) => {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
      show={show}
    >
      <div
        className={classNames(
          `overflow-auto bg-white rounded-t-sm rounded-b-md absolute z-30 max-h-[60vh] h-auto divide-y w-full md:w-auto -ml-2 md:-ml-0 -mt-12`,
          isSafari ? '' : 'shadow-md'
        )}
      >
        {children}
      </div>
    </Transition>
  );
};

export default DropdownPanel;
